
import {Options, Vue} from 'vue-class-component';
import LeftMenu from "@/components/LeftMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";

@Options({
  components: {FooterMenu, TopMenu, LeftMenu},
})
export default class DashboardView extends Vue {

}
