

import {Options, Vue} from "vue-class-component";
import ModalGeneric from "@/components/ModalGeneric.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Item} from "vue3-easy-data-table";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu},
})
export default class ListadoCategoriasDocumentosView extends Vue {
  headers = [
    {text: "ID", value: "id"},
    {text: "Nombre", value: "nombre"},
    {text: "Acciones", value: "operation"},
  ];
  items: Item = [];
  modalDeleteCategoria = false;
  categoriaDeleteId = '';

  getApiURL() {
    return (this as any).$apiURL;
  }

  mounted() {
    this.getListadoCategorias();
  }

  getListadoCategorias() {
    this.items = [];
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-all-categoria-documento', requestOptions).then(async response => {
      const data = await response.json();
      data.forEach((obj: any) => {
        this.items.push({
          "id": obj.id,
          "nombre": obj.nombre
        })
      })
    })
  }

  deleteCategoriaDocumento(item: any) {
    this.modalDeleteCategoria = true;
    this.categoriaDeleteId = item.id;
  }

  deleteCategoriaConfirm(categoriaDeleteId: any) {
    const requestOptions = {
      method: 'DELETE',
    };

    fetch(this.getApiURL() + 'backend/delete-categoria-documento/' + categoriaDeleteId, requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      this.modalDeleteCategoria = false;
      notify({
        title: data.message,
        type: 'success'
      });
      this.getListadoCategorias();
    })
  }

  AddCategoriaDocumento() {
    window.location.href = '/categorias/add-categoria-documento';
  }

  viewCategoriaDocumento(item: any) {
    window.location.href = '/categorias/editar-categoria-documento/' + item.id;
  }
}
