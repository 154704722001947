
import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import DatosBasicosView from "@/views/Gestion/Comunidades/Tabs/DatosBasicosView.vue";
import ModulosView from "@/views/Gestion/Comunidades/Tabs/ModulosView.vue";
import PropietariosView from "@/views/Gestion/Comunidades/Tabs/PropietariosView.vue";
import {useRoute} from "vue-router";

@Options({
  components: {PropietariosView, ModulosView, DatosBasicosView, FooterMenu, TopMenu, LeftMenu,},
})

export default class AddEditarComunidadView extends Vue {
  comunidadId: string | string[] = '';


  mounted() {
    const route = this.$route;
    if (typeof route.params.id !== 'undefined') {
      this.comunidadId = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;

    }
  }
}
