
import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Header, Item} from "vue3-easy-data-table";

@Options({
  components: {FooterMenu, TopMenu, LeftMenu},
})
export default class ListadoPropietariosView extends Vue {
  headers = [
    {text: "ID", value: "id"},
    {text: "Nombre", value: "nombre"},
    {text: "Apellidos", value: "direccion"},
    {text: "Comunidad", value: "poblacion"},
    {text: "Télefono", value: "provincia"},
    {text: "Email", value: "codigopostal"},
    {text: "Acciones", value: "operation"},
  ];
  items: Item = [];

  AddPropietario(){
    window.location.href = '/popietarios/anadir';
  }
  data() {
    return {
      headers: [
        {text: "ID", value: "id"},
        {text: "Nombre", value: "nombre"},
        {text: "Dirección", value: "direccion"},
        {text: "Poblacion", value: "poblacion"},
        {text: "Provincia", value: "provincia"},
        {text: "Código postal", value: "codigopostal"},
        {text: "Acciones", value: "operation"},
      ],
      items: [],
    }
  }
}
