import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardView from "@/views/DashboardView.vue";
import ListadoComunidadesView from "@/views/Gestion/Comunidades/ListadoComunidadesView.vue";
import AddEditarComunidadView from "@/views/Gestion/Comunidades/AddEditarComunidadView.vue";
import ListadoPropietariosView from "@/views/Gestion/Propietarios/ListadoPropietariosView.vue";
import NuevoEditarPropietariosView from "@/views/Gestion/Propietarios/NuevoEditarPropietariosView.vue";
import DocumentosModulosView from "@/views/Gestion/Comunidades/DocumentosModulosView.vue";
import ListadoUsuariosView from "@/views/Gestion/Usuarios/ListadoUsuariosView.vue";
import AddEditarUsuarioView from "@/views/Gestion/Usuarios/AddEditarUsuarioView.vue";
import ListadoIncidenciasView from "@/views/Gestion/Incidencias/ListadoIncidenciasView.vue";
import ListadoContactoView from "@/views/Gestion/Contacto/ListadoContactoView.vue";
import ListadoModulosView from "@/views/Gestion/Modulos/ListadoModulosView.vue";
import AddEditarModuloView from "@/views/Gestion/Modulos/AddEditarModuloView.vue";
import ListadoCategoriasDocumentosView from "@/views/Gestion/CategoriasDocumentos/ListadoCategoriasDocumentosView.vue";
import AddEditarCategoriaDocumentoView from "@/views/Gestion/CategoriasDocumentos/AddEditarCategoriaDocumentoView.vue";
import ConfiguracionGeneralView from "@/views/Gestion/Configuracion/ConfiguracionGeneralView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
    },
    {
        path: '/comunidades/listado',
        name: 'listadocomunidades',
        component: ListadoComunidadesView
    },
    {
        path: '/comunidades/anadir',
        name: 'anadircomunidad',
        component: AddEditarComunidadView
    },
    {
        path: '/comunidades/editar/:id',
        name: 'editarcomunidad',
        component: AddEditarComunidadView
    },
    {
        path: '/propietarios/listado',
        name: 'propietarioslistado',
        component: ListadoPropietariosView
    },
    {
        path: '/popietarios/anadir',
        name: 'anadirpropietarios',
        component: NuevoEditarPropietariosView
    },
    {
        path: '/propietarios/editar/:id',
        name: 'editarpropietarios',
        component: NuevoEditarPropietariosView
    },
    {
        path: '/comunidades/documentos-modulos/:id',
        name: 'documentosmodulos',
        component: DocumentosModulosView
    },
    {
        path: '/usuario/listado-usuario',
        name: 'listadousuario',
        component: ListadoUsuariosView
    },
    {
        path: '/usuario/add-usuario',
        name: 'addusuario',
        component: AddEditarUsuarioView
    },
    {
        path: '/usuario/editar-usuario/:id',
        name: 'editarusuario',
        component: AddEditarUsuarioView
    },
    {
        path: '/incidencias/listado-incidencias',
        name: 'listadoincidencias',
        component: ListadoIncidenciasView
    },
    {
        path: '/contacto/listado-contacto',
        name: 'listadocontacto',
        component: ListadoContactoView
    },
    {
        path: '/modulos/listado-modulos',
        name: 'listadomodulos',
        component: ListadoModulosView
    },
    {
        path: '/modulos/add-modulo',
        name: 'addmodulo',
        component: AddEditarModuloView
    },
    {
        path: '/modulos/editar-modulo/:id',
        name: 'editarmodulo',
        component: AddEditarModuloView
    },
    {
        path: '/categorias/listado-categorias-documentos',
        name: 'listadocategoriasdocimentos',
        component: ListadoCategoriasDocumentosView
    },
    {
        path: '/categorias/add-categoria-documento',
        name: 'addcategoriadocumento',
        component: AddEditarCategoriaDocumentoView
    },
    {
        path: '/categorias/editar-categoria-documento/:id',
        name: 'editarcategoriadocumento',
        component: AddEditarCategoriaDocumentoView
    },
    {
      path: '/configuracion/configuracion-general',
      name: 'configuraciongeneral',
      component: ConfiguracionGeneralView
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
