import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import Notifications from '@kyvg/vue3-notification'
import 'vue3-easy-data-table/dist/style.css';
import './assets/css/sb-admin-2.css'
require('./assets/js/sb-admin-2.js')

const app = createApp(App)

app.config.globalProperties.$apiURL = 'https://api.communalapp.es/api/';
app.config.globalProperties.$urlDomain = 'https://api.communalapp.es/';

app.use(router).use(Notifications).component('EasyDataTable', Vue3EasyDataTable).mount('#app')
