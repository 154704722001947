
import {Options, Vue} from "vue-class-component";
import ModalGeneric from "@/components/ModalGeneric.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Item} from "vue3-easy-data-table";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu},
})
export default class ListadoModulosView extends Vue {
  headers = [
    {text: "ID", value: "id"},
    {text: "Nombre", value: "nombre"},
    {text: "Icono", value: "iconoimage"},
    {text: "Tipo", value: "tipo"},
    {text: "Acciones", value: "operation"},
  ];
  items: Item = [];
  notImage = require('@/assets/image/not-image.png')
  modalDeleteModulo = false;
  moduloDeltedId = '';

  getApiURL() {
    return (this as any).$apiURL;
  }

  getDomainURL() {
    return (this as any).$urlDomain;
  }

  mounted() {
    this.getListadoModulos();
  }

  getListadoModulos() {
    this.items = [];
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-listado-modulos?is_custom=1', requestOptions).then(async response => {
      const data = await response.json();
      data.forEach((obj: any) => {
        this.items.push({
          "id": obj.id,
          "nombre": obj.nombre,
          "icono": (obj.icono !== '' && obj.icono !== null) ? this.getDomainURL() + obj.icono : this.notImage,
          "tipo": obj.tipo
        })
      })
    })
  }

  viewModulo(item: any) {
    window.location.href = '/modulos/editar-modulo/' + item.id;
  }

  deleteModulo(item: any) {
    this.moduloDeltedId = item.id;
    this.modalDeleteModulo = true;
  }

  deleteModuloConfirm(moduloId: any) {

    const requestOptions = {
      method: 'DELETE',
    };

    fetch(this.getApiURL() + 'backend/delete-modulo/' + moduloId, requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      this.modalDeleteModulo = false;
      notify({
        title: data.message,
        type: 'success'
      });
      this.getListadoModulos();
    })
  }


  AddModulo() {
    window.location.href = '/modulos/add-modulo';
  }
}
