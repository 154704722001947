
import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Header, Item} from "vue3-easy-data-table";
import {notify} from "@kyvg/vue3-notification";
import ModalGeneric from "@/components/ModalGeneric.vue";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu,},
})
export default class DocumentosModulosView extends Vue {
  comunidadId: string | string[] = '';
  modalOpenUploadDoc = false;
  modalOpenDeleteDoc = false;
  itemsModulos: any = [];
  nombreArchivo = '';
  docUpload: any;
  activo = 'false';
  categoriaDocumentos = '';
  moduloId = '';
  docId = '';
  moduloDeltedId = '';
  headers = [
    {text: "ID", value: "id"},
    {text: "Nombre", value: "nombre"},
    {text: "Categoría", value: "categoria"},
    {text: "Tipo archivo", value: "tipo_archivo"},
    {text: "Fecha", value: "fecha"},
    {text: "Acciones", value: "operation"},
  ];
  items: Item = [];
  isViewDataTable = true;
  textoContenido = '';
  notaIneteresId = '';
  itemsCategoria: any = [];
  iconPdf = require('@/assets/image/icons/icon-pdf.png');
  iconDoc = require('@/assets/image/icons/icon-doc.png');
  iconImage = require('@/assets/image/icons/icon-image.png');
  iconTxt = require('@/assets/image/icons/icon-txt.png');

  getApiURL() {
    return (this as any).$apiURL;
  }

  mounted() {
    const route = this.$route;
    if (typeof route.params.id !== 'undefined') {
      this.comunidadId = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
      this.getModulosComunidad(this.comunidadId);
    }

  }

  getModulosComunidad(comunidadId: any) {
    const requestOptions = {
      method: 'GET',
    };
    fetch(this.getApiURL() + 'backend/comunidad-modulo/' + comunidadId, requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      var i = 0;
      data.forEach((obj: any) => {
        if (obj.modulo_id !== 1 && obj.modulo_id !== 6 && obj.modulo_id !== 8) {
          this.itemsModulos.push({
            "id": obj.modulo_id,
            "nombre": obj.nombre,
            "tipo": obj.tipo
          });

          if (i === 0) {
            this.loadContentTable(obj.modulo_id, obj.tipo);
          }
          // this.isViewDataTable = (obj.tipo === "listado");
          i++;
        } else if (obj.modulo_id === 7) {
          this.isViewDataTable = false;
        }

      });
    })
  }

  getCategoriasDoc() {
    this.itemsCategoria = [];
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-all-categoria-documento', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      data.forEach((obj: any) => {
        this.itemsCategoria.push({
          "id": obj.id,
          "nombre": obj.nombre
        })
      })
    });
  }

  loadContentTable(moduloId: any, tipo: any) {
    if (moduloId === 7) {
      this.loadNotasInteres();
      this.isViewDataTable = (tipo === 'texto') ? false : true;
    } else {
      this.isViewDataTable = (tipo === 'listado');
      if (tipo === 'listado') {
        this.items = [];
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let formData = new URLSearchParams();
        formData.append('modulo_id', moduloId);
        formData.append('comunidad_id', this.comunidadId.toString());

        const requestOptions = {
          method: 'POST',
          body: formData,
          headers: myHeaders
        };


        fetch(this.getApiURL() + 'backend/get-documentos-modulo-comunidad', requestOptions).then(async response => {
          const data = await response.json();
          console.log(data);
          data.forEach((obj: any) => {
            this.items.push({
              "id": obj.id,
              "nombre": obj.nombre,
              "categoria": obj.categoriaNombre,
              "tipo_archivo": this.getTipoArchivo(obj.tipo_doc),
              "modulo_id": obj.modulo_id,
              "fecha": this.formatDate(obj.fecha_creacion)
            })
          });
        })
      } else if (tipo === 'texto') {
        this.loadContenidoTexto(this.comunidadId.toString(), moduloId);
      }
    }
  }

  getTipoArchivo(tipoDoc: any) {
    const patronImagePNG = /^image\/png$/;
    const patronTexto = /text/i;
    const patronApplicationPDF = /^application\/pdf$/;
    const patronSpreadsheet = /^application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet$/;
    const patronJPEG = /^image\/jpeg$/i;
    let imagen = '';
    if (patronImagePNG.test(tipoDoc)) {
      imagen = this.iconImage
    } else if (patronApplicationPDF.test(tipoDoc)) {
      imagen = this.iconPdf;
    } else if (patronSpreadsheet.test(tipoDoc)) {
      imagen = this.iconDoc;
    } else if (patronTexto.test(tipoDoc)) {
      imagen = this.iconTxt;
    } else if (patronJPEG.test(tipoDoc)) {
      imagen = this.iconImage;
    } else {
      imagen = this.iconTxt;
    }
    return imagen;
  }

  loadContenidoTexto(comunidadId: any, moduloId: any) {
    console.log(comunidadId);
    const requestOptions = {
      method: 'GET',
    };
    fetch(this.getApiURL() + 'backend/get-nota-texto-comunidad-modulo?comunidad_id=' + comunidadId + '&modulo_id=' + moduloId, requestOptions)
        .then(async response => {
          const data = await response.json();
          console.log('contenido texto');
          if (data.length > 0) {
            this.textoContenido = data[0].texto;
            this.notaIneteresId = data[0].id;
          }
        });

  }

  loadNotasInteres() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();
    formData.append('comunidad_id', this.comunidadId.toString());

    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: myHeaders
    };


    fetch(this.getApiURL() + 'backend/get-nota-comunidad', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      if (data.length > 0) {
        this.textoContenido = data[0].texto;
        this.notaIneteresId = data[0].id;
      }
    })
  }

  formatDate(date: any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }

  addDocumento(moduloId: any) {
    this.getCategoriasDoc();
    this.moduloId = moduloId;
    this.modalOpenUploadDoc = true;
  }

  saveTexto(moduloId: any, moduloTipo: any, notasInteresId: any) {

    if (moduloId === 7) { //Guardamos las notas de interes
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let formData = new URLSearchParams();
      formData.append('texto', this.textoContenido);
      formData.append('comunidad_id', this.comunidadId.toString());

      let metodo = (notasInteresId.length === 0) ? 'POST' : 'PUT';
      const requestOptions = {
        method: metodo,
        body: formData,
        headers: myHeaders
      };

      let url = (notasInteresId.length === 0) ? 'backend/create-notas-interes' : 'backend/update-notas-interes/' + notasInteresId;

      fetch(this.getApiURL() + url, requestOptions).then(async response => {
        const data = await response.json();
        var type = (typeof data.message === 'undefined') ? 'success' : 'error';
        var message = '';
        if (notasInteresId.length === 0) {
          message = (typeof data.message === 'undefined') ? 'Texto creada correctamente' : data.message;
        } else {
          message = (typeof data.message === 'undefined') ? 'Texto actualizada correctamente' : data.message;
        }

        notify({
          title: message,
          type: type
        });

      })
    } else {//Guardamos los textos genericos de los modulos personalizado
      this.saveNotasTexto(moduloId, notasInteresId);
    }


  }

  saveNotasTexto(moduloId: any, notaId: any) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();
    formData.append('texto', this.textoContenido);
    formData.append('comunidad_id', this.comunidadId.toString());
    formData.append('modulo_id', moduloId);

    let metodo = (notaId.length === 0) ? 'POST' : 'PUT';
    const requestOptions = {
      method: metodo,
      body: formData,
      headers: myHeaders
    };

    let url = (notaId.length === 0) ? 'backend/create-nota-texto' : 'backend/update-nota-texto/' + notaId;

    fetch(this.getApiURL() + url, requestOptions).then(async response => {
      const data = await response.json();
      var type = (typeof data.message === 'undefined') ? 'success' : 'error';
      var message = '';
      if (notaId.length === 0) {
        message = (typeof data.message === 'undefined') ? 'Texto creada correctamente' : data.message;
      } else {
        message = (typeof data.message === 'undefined') ? 'Texto actualizada correctamente' : data.message;
      }

      notify({
        title: message,
        type: type
      });

    })
  }

  selectedDocUpload(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        this.docUpload = event.target.files[0];
      }
    }
  }

  saveDoc(moduloId: any) {
    let myHeaders = new Headers();


    const formData = new FormData();
    formData.append('doc', this.docUpload);
    formData.append('nombre', this.nombreArchivo);
    formData.append('tipo_doc', this.docUpload.type);
    formData.append('comunidad_id', this.comunidadId.toString());
    formData.append('modulo_id', moduloId);
    formData.append('activo', this.activo);
    formData.append('categoria_documento_id', this.categoriaDocumentos);


    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: myHeaders
    };

    fetch(this.getApiURL() + 'backend/subir-documento', requestOptions).then(async response => {
      const data = await response.json();
      this.modalOpenUploadDoc = false;
      this.loadContentTable(moduloId, 'listado');
      var type = (typeof data.message === 'undefined') ? 'success' : 'error';
      var message = (typeof data.message === 'undefined') ? 'Documento subido correctamente' : data.message;
      notify({
        title: message,
        type: type
      });

    });
  }

  deleteItem(item: any) {
    this.docId = item.id;
    this.moduloDeltedId = item.modulo_id;
    this.modalOpenDeleteDoc = true;
  }

  deletedDoc(docId: any, moduloDeltedId: any) {

    const requestOptions = {
      method: 'DELETE',
    };

    fetch(this.getApiURL() + 'backend/delete-documento/' + docId, requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      this.modalOpenDeleteDoc = false;
      notify({
        title: data.message,
        type: 'success'
      });
      this.loadContentTable(moduloDeltedId, 'listado');
    })

  }
}
