
import {Options, Vue} from 'vue-class-component';

export default class LeftMenu extends Vue {

  totalIncidencias = 0;
  totalContacto = 0;

  getApiURL() {
    return (this as any).$apiURL;
  }

  mounted() {
    this.getTotalIncidenciasPendientes();
    this.getTotalContacto();
  }

  getTotalIncidenciasPendientes() {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-listado-incidencias?is_read=0', requestOptions).then(async response => {
      const data = await response.json();
      this.totalIncidencias = data.length;
    })
  }

  getTotalContacto(){
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-listado-contacto?is_read=0', requestOptions).then(async response => {
      const data = await response.json();
      this.totalContacto = data.length;
    })
  }
}
