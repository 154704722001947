
import {Options, Vue} from "vue-class-component";
import ModalGeneric from "@/components/ModalGeneric.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Item} from "vue3-easy-data-table";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu},
})
export default class ListadoContactoView extends Vue {
  headers = [
    {text: "ID", value: "id"},
    {text: "Comunidad", value: "comunidad"},
    {text: "Email", value: "email"},
    {text: "Asunto", value: "asunto"},
    {text: "Descripcion", value: "descripcion"},
    {text: "Fecha creación", value: "fecha_creacion"},
    {text: "Acciones", value: "operation"},
  ];
  items: Item = [];
  modalViewContacto = false;
  modalNombreComunidad = '';
  email = '';
  asunto = '';
  descripcion = '';
  fechaCreacion = '';

  getApiURL() {
    return (this as any).$apiURL;
  }

  mounted() {
    this.getListadoContacto();
  }

  getListadoContacto() {
    this.items = [];
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-listado-contacto', requestOptions).then(async response => {
      const data = await response.json();
      data.forEach((obj: any) => {
        this.items.push({
          "id": obj.id,
          "comunidad": obj.nombreComunidad,
          "email": obj.email,
          "asunto": obj.asunto,
          "descripcion": obj.descripcion,
          "fecha_creacion": this.formatDate(obj.fecha_creacion),
          "is_marked": obj.is_read
        });
      })
    })
  }

  viewContacto(item: any) {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-detalle-contacto/' + item.id, requestOptions).then(async response => {
      const data = await response.json();
      this.modalNombreComunidad = data[0].nombreComunidad;
      this.email = data[0].email;
      this.asunto = data[0].asunto;
      this.descripcion = data[0].descripcion;
      this.fechaCreacion = this.formatDate(data[0].fecha_creacion);
      this.modalViewContacto = true;
    })
  }

  markedChecked(item: any) {
    const requestOptions = {
      method: 'PUT',
    };

    fetch(this.getApiURL() + 'backend/contacto-marker-read/' + item.id, requestOptions).then(async response => {
      const data = await response.json();
      notify({
        title: 'Contacto leido',
        type: 'success'
      })
      this.getListadoContacto();
    })
  }

  formatDate(date: any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }
}
