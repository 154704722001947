

import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Header, Item} from "vue3-easy-data-table";
import {notify} from "@kyvg/vue3-notification";
import ModalGeneric from "@/components/ModalGeneric.vue";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu,},
})
export default class ListadoUsuariosView extends Vue {
  headers = [
    {text: "ID", value: "id"},
    {text: "Nombre", value: "nombre"},
    {text: "Apellidos", value: "apellidos"},
    {text: "Email", value: "email"},
    {text: "Rol", value: "role"},
    {text: "Acciones", value: "operation"},
  ];
  items: Item = [];
  modalOpenDeleteUser = false;
  deleteUserId = '';

  getApiURL() {
    return (this as any).$apiURL;
  }

  mounted() {
    this.getListadoUsuario();
  }

  getListadoUsuario() {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-all-user', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      data.forEach((obj: any) => {
        this.items.push({
          "id": obj.id,
          "nombre": obj.nombre,
          "apellidos": obj.apellidos,
          "email": obj.email,
          "role": obj.role,
        });
      });
    })
  }

  editItem(item: any) {
    console.log(item);
    window.location.href = '/usuario/editar-usuario/'+item.id;
  }

  deleteItem(item: any) {
    this.deleteUserId = item.id;
    this.modalOpenDeleteUser = true;
  }

  deleteUsuario(deleteUsuarioId: any) {
    const requestOptions = {
      method: 'DELETE',
    };

    fetch(this.getApiURL() + 'backend/delete-user/' + deleteUsuarioId, requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      this.modalOpenDeleteUser = false;
      notify({
        title: data.message,
        type: 'success'
      });
      this.getListadoUsuario();
    })
  }

  AddUsuario() {
    window.location.href = '/usuario/add-usuario';
  }
}
