<template>
  <div class="row mt-5">
    <div class="col-12">
      <div id="tableListadoComunidades">
        <EasyDataTable
            :headers="headers"
            :items="items"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {Vue} from "vue-class-component";

export default class PropietariosView extends Vue {
  data() {
    return {
      headers: [
        {text: "ID", value: "id"},
        {text: "Nombre y apellidos", value: "nombre_apellidos"},
        {text: "Piso", value: "piso"},
        {text: "Puerta", value: "puerta"},
        {text: 'Télefono', value: "telefono"},
        {text: "Acciones", value: "acciones"},
      ],
      items: [],
    }
  }
}
</script>

<style scoped>

</style>
