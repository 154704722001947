
import {Options, Vue} from "vue-class-component";
import ModalGeneric from "@/components/ModalGeneric.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu},
})
export default class AddEditarCategoriaDocumentoView extends Vue  {
  nombre = '';
  activo = '';
  isActivo = false;
  categoriaId: string | string[] = '';

  mounted() {
    const route = this.$route;
    if (typeof route.params.id !== 'undefined') {
      this.categoriaId = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
    }
  }

  getApiURL() {
    return (this as any).$apiURL;
  }

  guardarCategoria(){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();

    formData.append('nombre', this.nombre);
    formData.append('is_active', this.activo);

    let url = (this.categoriaId.length > 0) ? 'backend/update-categoria-documento/' + this.categoriaId : 'backend/create-categorias-documentos';
    let method = (this.categoriaId.length > 0) ? 'PUT' : 'POST';

    const requestOptions = {
      method: method,
      body: formData,
      headers: myHeaders
    };

    fetch(this.getApiURL() + url, requestOptions).then(async response => {
      const data = await response.json();
      var message = (typeof data.message !== 'undefined') ? data.message : (this.categoriaId.length > 0) ? 'Categoria actualizado' : 'Categoria creado';
      var type = (typeof data.message === 'undefined') ? 'success' : 'error';
      notify({
        title: message,
        type: type
      });
      setTimeout(function () {
        window.location.href = '/categorias/listado-categorias-documentos';
      }, 3000);
    });
  }

  cancelarCategoria(){
    window.location.href = '/categorias/listado-categorias-documentos';
  }
}
