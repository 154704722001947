
import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Header, Item} from "vue3-easy-data-table";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {FooterMenu, TopMenu, LeftMenu,},
})
export default class ListadoComunidadesView extends Vue {
  headers = [
    {text: "ID", value: "id"},
    {text: "Nombre", value: "nombre"},
    {text: "Dirección", value: "direccion"},
    {text: "Poblacion", value: "poblacion"},
    {text: "Provincia", value: "provincia"},
    {text: "Código postal", value: "codigopostal"},
    {text: "Acciones", value: "operation"},
  ];
  items: Item = [];

  getApiURL() {
    return (this as any).$apiURL;
  }

  AddComunidad() {
    window.location.href = '/comunidades/anadir';
  }

  editItem(item: any) {
    window.location.href = '/comunidades/editar/' + item.id;
  }

  deleteItem(item: any) {
    console.log(item);
  }

  editDocuments(item: any) {
    window.location.href = '/comunidades/documentos-modulos/' + item.id;
  }

  mounted() {
    this.getComunidades()
  }

  getComunidades() {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-all-comunidad', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      data.forEach((obj: any) => {
        this.items.push({
          "id": obj.id,
          "nombre": obj.nombre,
          "direccion": obj.direccion,
          "poblacion": obj.poblacion,
          "provincia": obj.provincia,
          "codigopostal": obj.codigo_postal,
        });
      });
    })
  }

  data() {
    return {
      headers: [
        {text: "ID", value: "id"},
        {text: "Nombre", value: "nombre"},
        {text: "Dirección", value: "direccion"},
        {text: "Poblacion", value: "poblacion"},
        {text: "Provincia", value: "provincia"},
        {text: "Código postal", value: "codigopostal"},
        {text: "Acciones", value: "operation"},
      ],
      items: [],
    }
  }
}
