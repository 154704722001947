
import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";

@Options({
  components: {FooterMenu, TopMenu, LeftMenu,},
})
export default class NuevoEditarPropietariosView extends Vue {
  nombre = '';
  apellidos = '';
  comunidad = '';
  telefono = '';
  email = '';
  activo = '';
  piso = '';
  puerta = '';
  itemsComunidades: any = [];

  getApiURL() {
    return (this as any).$apiURL;
  }

  mounted() {
    this.getComunidades();
  }

  getComunidades() {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-all-comunidad', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      data.forEach((obj: any) => {
        this.itemsComunidades.push({
          'id': obj.id,
          'nombre': obj.nombre,
        });
      });
    })
  }

  guardarPropietario() {
    console.log('guardar');
  }

  cancelarPropietario() {
    window.location.href = '/propietarios/listado';
  }
}
