

import {Options, Vue} from 'vue-class-component';
import axios from "axios";


@Options({
  components: {},
})
export default class HomeView extends Vue {
  email = '';
  password = '';
  errorMessage = '';
  isErrorMessage = false;

  getApiURL() {
    return (this as any).$apiURL;
  }


  iniciarSesion() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();
    formData.append('email', this.email);
    formData.append('password', this.password);
    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: myHeaders
    };
    fetch(this.getApiURL() + 'backend/login', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      if (data.length > 0) {
        localStorage.setItem('user', JSON.stringify(data[0]));
        window.location.href = '/dashboard';
      }else{
        this.isErrorMessage = true;
        this.errorMessage = 'Usuario incorrecto';
      }
    });
    //
  }
}
