
import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Header, Item} from "vue3-easy-data-table";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {FooterMenu, TopMenu, LeftMenu,},
})
export default class ConfiguracionGeneralView extends Vue {
  emailConfiguracion = '';

  mounted() {
    this.getConfiguracion();
  }


  getApiURL() {
    return (this as any).$apiURL;
  }

  getConfiguracion() {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-all-config', requestOptions).then(async response => {
      const data = await response.json();
      data.forEach((obj: any) => {
        switch (obj.key_config) {
          case 'email_config':
            this.emailConfiguracion = obj.valor_config;
            break;
        }
      });
    })
  }

  guardarConfiguracion() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();

    formData.append('email_config', this.emailConfiguracion);

    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: myHeaders
    };

    fetch(this.getApiURL() + 'backend/create-configuracion', requestOptions).then(async response => {
      const data = await response.json();
      let message = (typeof data.message !== 'undefined') ? data.message : 'Configuración actualizada correctamente';
      let type = (typeof data.message !== 'undefined') ? 'error' : 'success';
      notify({
        title: message,
        type: type
      });
    });

  }
}
