
import {Options, Vue} from "vue-class-component";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {Item} from "vue3-easy-data-table";
import ModalGeneric from "@/components/ModalGeneric.vue";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu},
})
export default class ListadoIncidenciasView extends Vue {
  headers = [
    {text: "ID", value: "id"},
    {text: "Comunidad", value: "comunidad"},
    {text: "Titulo", value: "titulo"},
    {text: "Descripcion", value: "descripcion"},
    {text: "Categoria", value: "categoria"},
    {text: "Fecha creación", value: "fecha_creacion"},
    {text: "Acciones", value: "operation"},
  ];
  notImage = require('@/assets/image/not-image.png');
  items: Item = [];
  modalViewIncidencia = false;
  modalNombreComunidad = '';
  modalCategoriaIncidencia = '';
  modalTituloIncidencia = '';
  modalDescriptionIncidencia = '';
  modalRutaImagenIncidencia = '';
  modalRutaImagenIncidencia2 = '';
  modalRutaImagenIncidencia3 = '';
  modalFechaCreacion = '';

  getApiURL() {
    return (this as any).$apiURL;
  }

  getDomainURL() {
    return (this as any).$urlDomain;
  }

  mounted() {
    this.getListadoIncidencias();
  }

  markedChecked(item: any) {
    const requestOptions = {
      method: 'PUT',
    };

    fetch(this.getApiURL() + 'backend/incidencia-marker-read/' + item.id, requestOptions).then(async response => {
      const data = await response.json();
      notify({
        title: 'Incidencia leida',
        type: 'success'
      })
      this.getListadoIncidencias();
    })
  }

  viewIncidencia(item: any) {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-incidencia/' + item.id, requestOptions).then(async response => {
      const data = await response.json();
      this.modalNombreComunidad = data[0].nombreComunidad;
      this.modalCategoriaIncidencia = data[0].nombreCategoria;
      this.modalTituloIncidencia = data[0].titulo;
      this.modalDescriptionIncidencia = data[0].descripcion;
      this.modalRutaImagenIncidencia = (data[0].ruta_imagen.length > 0) ? this.getDomainURL() + data[0].ruta_imagen : '';
      this.modalRutaImagenIncidencia2 = (data[0].ruta_imagen2 !== null) ? this.getDomainURL() + data[0].ruta_imagen2 : '';
      this.modalRutaImagenIncidencia3 = (data[0].ruta_imagen3 !== null) ? this.getDomainURL() + data[0].ruta_imagen3 : '';
      this.modalFechaCreacion = this.formatDate(data[0].fecha_creacion);
      this.modalViewIncidencia = true;
    })
  }

  getListadoIncidencias() {
    this.items = [];
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-listado-incidencias', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      data.forEach((obj: any) => {
        this.items.push({
          "id": obj.id,
          "comunidad": obj.nombreComunidad,
          "titulo": obj.titulo,
          "descripcion": obj.descripcion,
          "categoria": obj.nombreCategoria,
          "fecha_creacion": this.formatDate(obj.fecha_creacion),
          "is_marked": obj.is_read
        })
      });
    })
  }

  formatDate(date: any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }
}
