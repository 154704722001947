<template>
  <footer class="sticky-footer bg-white">
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span>Copyright &copy; Communal {{ fechaActual }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
import {Vue} from "vue-class-component";

export default class FooterMenu extends Vue {
  data() {
    return {
      fechaActual: new Date().getFullYear()
    }
  }

}
</script>

<style scoped>

</style>
