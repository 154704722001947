
import {Options, Vue} from "vue-class-component";
import PropietariosView from "@/views/Gestion/Comunidades/Tabs/PropietariosView.vue";
import ModulosView from "@/views/Gestion/Comunidades/Tabs/ModulosView.vue";
import DatosBasicosView from "@/views/Gestion/Comunidades/Tabs/DatosBasicosView.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {PropietariosView, ModulosView, DatosBasicosView, FooterMenu, TopMenu, LeftMenu},
})
export default class AddEditarUsuarioView extends Vue {

  usuarioId: string | string[] = '';
  nombre = '';
  apellidos = '';
  email = '';
  rol = '';
  username = '';
  password = '';
  activo = '';
  isActivo = false;


  mounted() {
    const route = this.$route;
    if (typeof route.params.id !== 'undefined') {
      this.usuarioId = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
      this.getUsuario(this.usuarioId);
    }
  }

  getApiURL() {
    return (this as any).$apiURL;
  }

  getUsuario(usuarioId: any) {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-user/' + usuarioId, requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      this.nombre = data.nombre;
      this.apellidos = data.apellidos;
      this.email = data.email;
      this.rol = data.role;
      this.username = data.username;
      this.isActivo = data.is_active;
    })
  }

  cancelarUsuario() {
    window.location.href = '/usuario/listado-usuario';
  }

  guardarUsuario() {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();
    formData.append('nombre', this.nombre);
    formData.append('apellidos', this.apellidos);
    formData.append('email', this.email);
    formData.append('role', this.rol);
    formData.append('username', this.username);
    formData.append('password', this.password);
    formData.append('is_active', this.activo);
    formData.append('is_deleted', 'false');
    let metodo = (this.usuarioId.length === 0) ? 'POST' : 'PUT';
    const requestOptions = {
      method: metodo,
      body: formData,
      headers: myHeaders
    };

    let url = (this.usuarioId.length === 0) ? 'backend/create-user' : 'backend/update-user/' + this.usuarioId;

    fetch(this.getApiURL() + url, requestOptions).then(async response => {
      const data = await response.json();
      var type = (typeof data.message === 'undefined') ? 'success' : 'error';
      var message = '';
      if (this.usuarioId.length === 0) {
        message = (typeof data.message === 'undefined') ? 'Usuario creada correctamente' : data.message;
      } else {
        message = (typeof data.message === 'undefined') ? 'Usuario actualizada correctamente' : data.message;
      }

      notify({
        title: message,
        type: type
      });

      if (typeof data.message === 'undefined') {
        let usuarioId = (this.usuarioId.length === 0) ? data.id : this.usuarioId;
        setTimeout(function () {
          window.location.href = '/usuario/editar-usuario/' + usuarioId;
        }, 3000);
      }

    })
  }
}
