
import {Options, Vue} from "vue-class-component";
import ModalGeneric from "@/components/ModalGeneric.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import TopMenu from "@/components/TopMenu.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {ModalGeneric, FooterMenu, TopMenu, LeftMenu},
})
export default class AddEditarModuloView extends Vue {
  moduloId: string | string[] = '';
  nombre = '';
  tipo = '';
  activo = '';
  isActivo = false;
  isActivoAll = false;
  activoAll = '';
  imageModulo = require('@/assets/image/not-image.png');
  imageUpload = '';

  mounted() {
    const route = this.$route;
    if (typeof route.params.id !== 'undefined') {
      this.moduloId = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
    }
  }

  getApiURL() {
    return (this as any).$apiURL;
  }

  getDomainURL() {
    return (this as any).$urlDomain;
  }

  changeImageModulo(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageModulo = reader.result;
        };
        reader.readAsDataURL(file);
        this.imageUpload = event.target.files[0];
      }
    }
  }

  cargarImagen() {
    (this.$refs as any).fileInputModulo.click();
  }

  guardarModulo() {
    let myHeaders = new Headers();
    const formData = new FormData();
    formData.append('icono', this.imageUpload);
    formData.append('nombre', this.nombre);
    formData.append('tipo', this.tipo);
    formData.append('activoAll', this.activoAll);

    let url = (this.moduloId.length > 0) ? 'backend/update-modulo/' + this.moduloId : 'backend/create-modulo';
    let method = (this.moduloId.length > 0) ? 'PUT' : 'POST';

    const requestOptions = {
      method: method,
      body: formData,
      headers: myHeaders
    };

    fetch(this.getApiURL() + url, requestOptions).then(async response => {
      const data = await response.json();
      var message = (typeof data.message !== 'undefined') ? data.message : (this.moduloId.length > 0) ? 'Módulo actualizado' : 'Módulo creado';
      var type = (typeof data.message === 'undefined') ? 'success' : 'error';
      notify({
        title: message,
        type: type
      });
      setTimeout(function () {
        window.location.href = '/modulos/listado-modulos';
      }, 3000);
    });

  }

  cancelarModulo() {
    window.location.href = '/modulos/listado-modulos';
  }
}
