
import {Vue} from "vue-class-component";
import {notify} from "@kyvg/vue3-notification";

export default class ModulosView extends Vue {
  imageComunidad = require('@/assets/image/icons/ico-comunidad.png');
  isImageComunidadCustom = false;
  imageDocumentos = require('@/assets/image/icons/ico-documentos.png');
  isImageDocumentosCustom = false;
  imagePresupuestos = require('@/assets/image/icons/ico-presupuesto.png');
  isImagePresupuestoCustom = false;
  imageCuentas = require('@/assets/image/icons/ico-cuentas.png');
  isImageCuentasCustom = false;
  imageNormativas = require('@/assets/image/icons/ico-normas.png');
  isImageNormativasCustom = false;
  imageContacto = require('@/assets/image/icons/ico-contacto.png');
  isImageContactoCustom = false;
  imageNotas = require('@/assets/image/icons/ico-notas.png');
  isImageNotasCustom = false;
  imageIncidencias = require('@/assets/image/icons/ico-incidencias.png');
  isImageIncidenciasCustom = false;
  isCheckedComunidad = false;
  isCheckedDocumentos = false;
  isCheckedPresupuestos = false;
  isCheckedCuentas = false;
  isCheckedNormativa = false;
  isCheckedContacto = false;
  isCheckedNotas = false;
  isCheckedIncidencias = false;

  comunidadId: string | string[] = '';
  customModules: any = [];


  mounted() {
    const route = this.$route;
    if (typeof route.params.id !== 'undefined') {
      this.comunidadId = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
      this.getModulosCustom(this.comunidadId);
      this.getAllModulosComunidad(this.comunidadId);
    }
  }

  getApiURL() {
    return (this as any).$apiURL;
  }

  getApiDomain() {
    return (this as any).$urlDomain;
  }

  getModulosCustom(comunidadId: any) {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/get-listado-modulos?is_custom=1', requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      data.forEach((obj: any) => {
        this.customModules.push({
          id: obj.id,
          icono: this.getApiDomain() + obj.icono,
          nombre: obj.nombre,
        })
      })
    });
  }

  getAllModulosComunidad(comunidadId: any) {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiURL() + 'backend/comunidad-modulo/' + comunidadId, requestOptions).then(async response => {
      const data = await response.json();
      data.forEach((obj: any) => {
        switch (obj.modulo_id) {
          case 1:
            this.isCheckedComunidad = true;
            if (obj.icon !== '' && obj.icon !== null) {
              this.imageComunidad = this.getApiDomain() + obj.icon;
              this.isImageComunidadCustom = true;
            }
            break;
          case 2:
            this.isCheckedDocumentos = true;
            if (obj.icon !== '' && obj.icon !== null) {
              this.imageDocumentos = this.getApiDomain() + obj.icon;
              this.isImageDocumentosCustom = true;
            }
            break;
          case 3:
            this.isCheckedPresupuestos = true;
            if (obj.icon !== '' && obj.icon !== null) {
              this.imagePresupuestos = this.getApiDomain() + obj.icon;
              this.isImagePresupuestoCustom = true;
            }
            break;
          case 4:
            this.isCheckedCuentas = true;
            if (obj.icon !== '' && obj.icon !== null) {
              this.imageCuentas = this.getApiDomain() + obj.icon;
              this.isImageCuentasCustom = true;
            }
            break;
          case 5:
            this.isCheckedNormativa = true
            if (obj.icon !== '' && obj.icon !== null) {
              this.imageNormativas = this.getApiDomain() + obj.icon;
              this.isImageNormativasCustom = true;
            }
            break;
          case 6:
            this.isCheckedContacto = true;
            if (obj.icon !== '' && obj.icon !== null) {
              this.imageContacto = this.getApiDomain() + obj.icon;
              this.isImageContactoCustom = true;
            }
            break;
          case 7:
            this.isCheckedNotas = true;
            if (obj.icon !== '' && obj.icon !== null) {
              this.imageNotas = this.getApiDomain() + obj.icon;
              this.isImageNotasCustom = true;
            }
            break;
          case 8:
            this.isCheckedIncidencias = true;
            if (obj.icon !== '' && obj.icon !== null) {
              this.imageIncidencias = this.getApiDomain() + obj.icon;
              this.isImageIncidenciasCustom = true;
            }
            break;
          default:
            if(typeof (this.$refs as any)['check' + obj.modulo_id] !== 'undefined') {
              (this.$refs as any)['check' + obj.modulo_id][0].checked = true;
              if (obj.icon !== '' && obj.icon !== null) {
                (this.$refs as any)['image' + obj.modulo_id][0].src = this.getApiDomain() + obj.icon;
                (this.$refs as any)['icondelete' + obj.modulo_id][0].removeClass('none')
              }
            }
            break;
        }
      });
    })
  }

  cargarImagen(tipoImagen: any) {
    switch (tipoImagen) {
      case 'comunidad':
        (this.$refs as any).fileInputComunidad.click();
        break;
      case 'documentos':
        (this.$refs as any).fileInputDocumentos.click();
        break;
      case 'presupuestos':
        (this.$refs as any).fileInputPresupuestos.click();
        break;
      case 'cuentas':
        (this.$refs as any).fileInputCuentas.click();
        break;
      case 'normativa':
        (this.$refs as any).fileInputNormativa.click();
        break;
      case 'contacto':
        (this.$refs as any).fileInputContacto.click();
        break;
      case 'nota':
        (this.$refs as any).fileInputNotas.click();
        break;
      case 'incidencia':
        (this.$refs as any).fileInputIncidencia.click();
        break;
    }
  }

  deleteCustomImage(moduloId: any) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();
    formData.append('comunidad_id', this.comunidadId.toString());
    formData.append('modulo_id', moduloId);

    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: myHeaders
    };

    fetch(this.getApiURL() + 'backend/delete-imagen-modulo', requestOptions).then(async response => {
      const data = await response.json();
      this.getIconImageAndHideShowRemove(moduloId);
    });
  }

  getIconImageAndHideShowRemove(moduloId: any) {
    switch (moduloId) {
      case 1:
        this.isImageComunidadCustom = false;
        break;
      case 2:
        this.isImageDocumentosCustom = false;
        break;
      case 3:
        this.isImagePresupuestoCustom = false;
        break;
      case 4:
        this.isImageCuentasCustom = false;
        break;
      case 5:
        this.isImageNormativasCustom = false;
        break;
      case 6:
        this.isImageContactoCustom = false;
        break;
      case 7:
        this.isImageNotasCustom = false;
        break;
      case 8:
        this.isImageIncidenciasCustom = false;
        break;
      default:
        (this.$refs as any)['icondelete' + moduloId][0].addClass('none')
        break;
    }
  }

  cargarImagenModuloCustom(moduloId: any) {
    console.log(moduloId);
    //let referencia = (this.$refs as any);
    (this.$refs as any)[moduloId][0].click();
  }

  activeDesactModule(typeModulo: any, moduloId: any) {
    let isActive = false;
    switch (typeModulo) {
      case 'comunidad':
        this.isCheckedComunidad = !this.isCheckedComunidad;
        isActive = this.isCheckedComunidad;
        break;
      case 'documentos':
        this.isCheckedDocumentos = !this.isCheckedDocumentos;
        isActive = this.isCheckedDocumentos;
        break;
      case 'presupuestos':
        this.isCheckedPresupuestos = !this.isCheckedPresupuestos;
        isActive = this.isCheckedPresupuestos;
        break;
      case 'cuentas':
        this.isCheckedCuentas = !this.isCheckedCuentas;
        isActive = this.isCheckedCuentas;
        break;
      case 'normativa':
        this.isCheckedNormativa = !this.isCheckedNormativa;
        isActive = this.isCheckedNormativa;
        break;
      case 'contacto':
        this.isCheckedContacto = !this.isCheckedContacto;
        isActive = this.isCheckedContacto;
        break;
      case 'notas':
        this.isCheckedNotas = !this.isCheckedNotas;
        isActive = this.isCheckedNotas;
        break;
      case 'incidencias':
        this.isCheckedIncidencias = !this.isCheckedIncidencias;
        isActive = this.isCheckedIncidencias;
        break;
    }
    this.saveModuloComunidad(isActive, moduloId);
  }

  saveModuloComunidad(isChecked: any, moduloId: any) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();
    formData.append('comunidad_id', this.comunidadId.toString());
    formData.append('modulo_id', moduloId);
    formData.append('enabled', isChecked);

    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: myHeaders
    };

    fetch(this.getApiURL() + 'backend/enabled-disabled-comunidad-modulo', requestOptions).then(async response => {
      const data = await response.json();
      var message = (!isChecked) ? 'Módulo deshabilitado correctamente' : 'Módulo habilitado correctamente';
      notify({
        title: message,
        type: 'success'
      });
    });
  }

  changeImageComunidad(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageComunidad = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 1);
      }
    }
  }

  async uploadImage(image: any, moduloId: any) {
    let myHeaders = new Headers();


    const formData = new FormData();
    formData.append('image', image);
    formData.append('comunidad_id', this.comunidadId.toString());
    formData.append('modulo_id', moduloId);


    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: myHeaders
    };

    fetch(this.getApiURL() + 'backend/subir-icono', requestOptions).then(async response => {
      const data = await response.json();

    });

  }

  changeImageDocumentos(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageDocumentos = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 2);
      }
    }
  }

  changeImagePresupuestos(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imagePresupuestos = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 3);
      }
    }
  }

  changeImageCuentas(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageCuentas = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 4);
      }
    }
  }

  changeImageNormativa(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageNormativas = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 5);
      }
    }
  }

  changeImageContacto(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageContacto = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 6);
      }
    }
  }

  changeImageNotas(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageNotas = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 7);
      }
    }
  }

  changeIncidencia(event: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageIncidencias = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], 8);
      }
    }
  }

  changeCustomModule(event: any, moduloId: any) {
    if (event && event.target && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          (this.$refs as any)['image' + moduloId][0].src = reader.result;
        };
        reader.readAsDataURL(file);
        this.uploadImage(event.target.files[0], moduloId);
      }
    }
  }

  activeCustomModule(event: any, moduloId: any) {
    this.saveModuloComunidad(event.target.checked, moduloId);
  }
}
