
import {Options, Vue} from "vue-class-component";
import {notify} from "@kyvg/vue3-notification";

@Options({
  components: {},
})
export default class DatosBasicosView extends Vue {
  nombre = '';
  nombre_gestor = '';
  direccion = '';
  poblacion = '';
  provincia = '';
  codigo_postal = '';
  clave_acceso = '';
  isActivo = 'false';
  isCheckedActivo = false;
  comunidadId: string | string[] = '';


  mounted() {
    const route = this.$route;
    if (typeof route.params.id !== 'undefined') {
      this.comunidadId = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
      this.getDatosComunidad(this.comunidadId);
    }
  }

  getApiUrl() {
    return (this as any).$apiURL;
  }

  getDatosComunidad(comunidadId: any) {
    const requestOptions = {
      method: 'GET',
    };

    fetch(this.getApiUrl() + 'backend/get-comunidad/' + comunidadId, requestOptions).then(async response => {
      const data = await response.json();
      console.log(data);
      this.nombre = data[0].nombre;
      this.nombre_gestor = data[0].nombre_gestor;
      this.direccion = data[0].direccion;
      this.poblacion = data[0].poblacion;
      this.provincia = data[0].provincia;
      this.codigo_postal = data[0].codigo_postal;
      this.isActivo = data[0].is_active;
      this.isCheckedActivo = data[0].is_active;
    });
  }

  guardarComunidad() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let formData = new URLSearchParams();
    formData.append('nombre', this.nombre);
    formData.append('nombre_gestor', this.nombre_gestor);
    formData.append('direccion', this.direccion);
    formData.append('poblacion', this.poblacion);
    formData.append('provincia', this.provincia);
    formData.append('codigo_postal', this.codigo_postal);
    formData.append('clave_acceso', this.clave_acceso);
    formData.append('is_active', this.isActivo);
    let metodo = (this.comunidadId.length === 0) ? 'POST' : 'PUT';
    const requestOptions = {
      method: metodo,
      body: formData,
      headers: myHeaders
    };

    let url = (this.comunidadId.length === 0) ? 'backend/create-comunidad' : 'backend/update-comunidad/' + this.comunidadId;

    fetch(this.getApiUrl() + url, requestOptions).then(async response => {
      const data = await response.json();
      var type = (typeof data.message === 'undefined') ? 'success' : 'error';
      var message = '';
      if (this.comunidadId.length === 0) {
        message = (typeof data.message === 'undefined') ? 'Comunidad creada correctamente' : data.message;
      } else {
        message = (typeof data.message === 'undefined') ? 'Comunidad actualizada correctamente' : data.message;
      }

      notify({
        title: message,
        type: type
      });

      if (typeof data.message === 'undefined') {
        let comunidadId = (this.comunidadId.length === 0) ? data.id : this.comunidadId;
        setTimeout(function () {
          window.location.href = '/comunidades/editar/' + comunidadId;
        }, 3000);
      }

    })
  }

  cancelarComunidad() {
    window.location.href = '/comunidades/listado';
  }
}
